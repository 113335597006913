@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/layout';

.gridContainer {
    @include grid;

    max-width: 80rem;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 0;
    padding: 0 1rem;

    &::after {
        position: absolute;
        content: '';
        background: $gray;
        width: calc(100% - 2rem);
        height: 0.1rem;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    &.relatedProjects {
        padding: 0 3.5rem;
        margin-bottom: 0;

        &::after {
            display: none;
        }
    }
}

.container {
    width: calc(50% - 0.5rem);
    padding-bottom: 2rem;
    transition: color $fast $easeOutQuad;

    .relatedProjects & {
        width: 100%;
        padding-bottom: 4rem;
    }

    a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
    }

    @media (hover: hover) {
        &:hover {
            color: $gold;

            .caption {
                color: $gold;
            }

            img {
                transition: transform 2s $easeOutQuad;
                transform: scale(1.1);
            }
        }
    }

    &:nth-child(9) {
        display: none;
    }
}

.aspect {
    position: relative;
    height: 0;
    padding-bottom: 125%;
    margin-bottom: 1rem;
    overflow: hidden;
    background-color: $gold;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    margin-bottom: 0.4rem;
}

.caption {
    text-transform: uppercase;
    color: $grayDark;
    transition: color $fast $easeOutQuad;
}

.noResults {
    grid-column: 1 / -1;
    padding: 0 2rem 1rem;
}

.button {
    margin: 0 auto;
    margin-top: var(--cta-margin-top);
}

@include small {
    .gridContainer {
        display: grid;
        padding: 0 var(--grid-margin);
        grid-gap: 0 var(--grid-gap);
        grid-row-gap: 3rem;
        max-width: var(--container-max-width);
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: var(--component-margin-bottom);

        &::after {
            width: calc(100% - 9rem);
        }

        &.relatedProjects {
            padding: 0 6rem;
        }
    }

    .container {
        width: 100%;
        padding-bottom: 0;

        &:nth-child(9) {
            display: block;
        }
    }

    .title {
        margin-bottom: 0.5rem;
    }
}

@include medium {
    .gridContainer {
        grid-row-gap: 5rem;

        &.relatedProjects {
            padding: 0 var(--grid-margin);
        }

        &::after {
            width: calc(100% - 22rem);
        }
    }

    .container {
        width: 100%;
        padding-bottom: 0;
    }

    .lineContainer {
        margin-top: calc(var(--component-margin-bottom) - 4rem);
    }

    .image {
        margin-bottom: 1.5rem;
    }
}

@include large {
    .gridContainer {
        padding-bottom: calc(var(--component-margin-bottom) - 5rem);

        &::after {
            width: calc(100% - 20rem);
        }
    }
}
