// Font Names
// -------------------------

@font-face {
    font-family: 'Nanum Myeongjo-fallback';
    size-adjust: 108.85000000000004%;
    ascent-override: 77%;
    src: local('Times New Roman');
}

@font-face {
    font-family: 'Avant Garde-fallback';
    size-adjust: 86%;
    src: local('Arial Black');
}

$nanum: 'Nanum Myeongjo', 'Nanum Myeongjo-fallback', serif !default;
$avantGarde: itc-avant-garde-gothic-pro, 'Avant Garde-fallback', sans-serif !default;
