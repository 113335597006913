@mixin grid {
    display: grid;
    padding-left: var(--grid-margin);
    padding-right: var(--grid-margin);
    grid-gap: 0 var(--grid-gap);
    grid-template-columns: var(--grid-template-columns);
    grid-template-rows: auto;
    max-width: var(--container-max-width);
    margin: 0 auto;
}
