@import 'styles/breakpoints';

.root {
    overflow: hidden;
    display: grid;
    gap: 4rem 0;
    padding: 6rem 0 5rem;
}

.carousel {
    --carousel-autoscroll: -0.035;
    --carousel-gap: 3rem;

    pointer-events: none;

    &:nth-child(2) {
        --carousel-autoscroll: 0.035;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.logo {
    width: 6rem;
    height: 4rem;
    filter: grayscale(100%);

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@include small {
    .root {
        gap: 5rem;
        padding: 8rem 0 6rem;
    }

    .carousel {
        --carousel-autoscroll: -0.055;
        --carousel-gap: 5rem;

        &:nth-child(2) {
            --carousel-autoscroll: 0.055;
        }
    }

    .logo {
        width: 11rem;
        height: 7rem;
    }
}

@include medium {
    .root {
        gap: 8rem 0;
        padding: 15rem 0 10rem;
    }

    .carousel {
        --carousel-autoscroll: -0.075;
        --carousel-gap: 8rem;

        &:nth-child(2) {
            --carousel-autoscroll: 0.075;
        }
    }

    .logo {
        width: 15rem;
        height: 10rem;
    }
}
