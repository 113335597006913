.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.paragraph {
    margin: 0 0 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.orderedList,
.unorderedList {
    padding-left: 2rem;
}

.unorderedList {
    list-style: disc;
}
