// Timing fns
// --------------------------

$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$easeIn: cubic-bezier(0.42, 0, 1, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInOut: cubic-bezier(0.42, 0, 0.58, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOut: cubic-bezier(0, 0, 0.58, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);

// Durations
// -------------------------

$baseDuration: 300ms;
$fast: $baseDuration * 0.5;
$slow: $baseDuration * 1.5;
$superSlow: $baseDuration * 2;

// Keyframes
// -------------------------

@keyframes fadeIn {
    0% {
        opacity: 0.01;
    }

    100% {
        opacity: 1;
    }
}
