@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/typography';

.root {
    position: relative;
    width: 100%;
}

.inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.headline {
    @include headingMedium;

    font-size: clamp(2.1rem, 4vw, 4.8rem);
    transform: translateY(-65%);
    color: $gold;
}

.svgDimension {
    position: relative;
    width: 100%;

    svg {
        visibility: hidden;
    }
}

.mek {
    position: absolute;
    width: 100%;
    clip-path: inset(0 63% 0 0);
    transition: transform 1200ms $easeOutExpo;
    transform: translateX(8%);
    will-change: transform;

    .isAkimbo & {
        transform: translateX(0%);
    }
}

.anism {
    position: absolute;
    width: 100%;
    clip-path: inset(0 0 0 46%);
    transition: transform 1200ms $easeOutExpo;
    transform: translateX(-5%);
    will-change: transform;

    .isAkimbo & {
        transform: translateX(0%);
    }
}

.logoSvg {
    width: 100%;
}

.icons {
    position: absolute;
    top: -40%;
    left: 50%;
    width: 24%;
    height: 155%;
    transform: translateX(-50%) translateX(-32%);
}

.icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: none;
        top: 0;
        left: 0;
        object-fit: contain;
    }

    transform: scale(0);

    &.isActive {
        animation: popInPopOut 4s linear 1 forwards 0.2s;

        &.lastIcon {
            animation-name: popIn;
        }
    }
}

@keyframes popInPopOut {
    0% {
        transform: scale(0.2);
        animation-timing-function: $easeInSine;
    }

    10% {
        transform: scale(1.1);
        animation-timing-function: $easeOutCirc;
    }

    80% {
        transform: scale(0.95);
        animation-timing-function: $easeOutCubic;
    }

    100% {
        transform: scale(0);
        animation-timing-function: $easeOutSine;
    }
}

@keyframes popIn {
    0% {
        transform: scale(0.2);
        animation-timing-function: $easeInSine;
    }

    10% {
        transform: scale(1.1);
        animation-timing-function: $easeOutCirc;
    }

    99% {
        transform: scale(0.95);
        animation-timing-function: $easeOutCubic;
    }

    100% {
        transform: scale(1);
        animation-timing-function: $easeOutSine;
    }
}

@include medium {
    .headline {
        transform: translateY(-5%);
    }
}
