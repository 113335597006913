@import 'styles/colors';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 18rem;
    margin-top: -10rem;
    pointer-events: none;
}

.popcornBucket {
    position: absolute;
    top: -4rem;
}

@include small {
    .popcornBucket {
        top: -25rem;
    }
}

@include large {
    .root {
        padding-bottom: 35rem;
    }

    .popcornBucket {
        width: 75%;
        top: -25rem;
    }
}
