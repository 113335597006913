@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    width: 13rem;
    height: 9rem;

    .icon {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .arrow {
        transition: all $fast $easeOutQuad;
    }

    .iconHover {
        position: absolute;
        opacity: 0;
        transition: all $fast $easeOutQuad;
    }

    @media (hover: hover) {
        &:hover {
            .arrow {
                opacity: 0;
                transition: all $fast $easeOutQuad;
            }

            .iconHover {
                opacity: 1;
            }
        }
    }
}
