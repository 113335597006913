$base: 320px;
$small: 768px;
$medium: 1024px;
$large: 1440px;
$max: 1920px;

@mixin small {
    @media (min-width: $small) {
        @content;
    }
}

@mixin medium {
    @media (min-width: $medium) {
        @content;
    }
}

@mixin large {
    @media (min-width: $large) {
        @content;
    }
}

@mixin max {
    @media (min-width: $max) {
        @content;
    }
}

@mixin baseOnly {
    @media (min-width: $base) and (max-width: #{$small - 1}) {
        @content;
    }
}

@mixin smallOnly {
    @media (min-width: $small) and (max-width: #{$medium - 1}) {
        @content;
    }
}

@mixin mediumOnly {
    @media (min-width: $medium) and (max-width: #{$large - 1}) {
        @content;
    }
}
