@import 'styles/colors';
@import 'styles/breakpoints';
@import 'styles/layout';

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    background-color: $beige;
}

.logo {
    width: 80vw;
    max-width: 103rem;
    margin-top: 7%;
}

.textContainer {
    @include grid;

    padding: var(--block-padding);
    text-align: center;

    b {
        color: $pink;

        &:nth-child(2) {
            color: $blue;
        }
    }
}

.greetings {
    margin-bottom: 1.5rem;
    color: $pink;
}

.textInner {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / -1;
}

.headline {
    padding: 0 3rem;
    max-width: 68rem;
}

.vennContainer {
    width: 100%;
    overflow: hidden;
    margin-bottom: 8rem;
}

@include small {
    .headline {
        padding: 0;
    }
}

@include medium {
    .textInner {
        grid-column: 2 / -2;
    }
}

@include large {
    .textContainer {
        padding-bottom: 10rem;
    }

    .greetings {
        margin-bottom: 3rem;
    }

    .textInner {
        grid-column: 3 / -3;
    }

    .headline {
        max-width: none;
    }

    .vennContainer {
        margin-bottom: 15rem;
    }
}
