@import 'styles/fonts';

// display
@mixin displayXLarge {
    font-family: $avantGarde;
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.04em;
}

@mixin displayLarge {
    font-family: $avantGarde;
    font-size: 7.2rem;
    line-height: 1;
    letter-spacing: -0.02em;
}

@mixin displayMedium {
    font-family: $avantGarde;
    font-size: 6.4rem;
    line-height: 1;
    letter-spacing: -0.02em;
}

@mixin displaySmall {
    font-family: $avantGarde;
    font-size: 5.2rem;
    line-height: 1;
    letter-spacing: -0.02em;
}

@mixin displayXSmall {
    font-family: $avantGarde;
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: -0.02em;
}

// heading
@mixin headingLarge {
    font-family: $avantGarde;
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: -0.02em;
}

@mixin headingMedium {
    font-family: $avantGarde;
    font-size: 3rem;
    line-height: 1.05;
    letter-spacing: -0.01em;
}

@mixin headingSmall {
    font-family: $avantGarde;
    font-size: 2.1rem;
    line-height: 1.2;
    letter-spacing: -0.01em;
}

@mixin headingXSmall {
    font-family: $avantGarde;
    font-size: 1.6rem;
    line-height: 1.1;
    letter-spacing: 0;
}

// body
@mixin bodyLarge {
    font-family: $nanum;
    font-size: 2.1rem;
    line-height: 1.4;
    letter-spacing: 0;
}

@mixin bodyMedium {
    font-family: $nanum;
    font-size: 1.9rem;
    line-height: 1.4;
    letter-spacing: 0;
}

@mixin bodySmall {
    font-family: $nanum;
    font-size: 1.6rem;
    line-height: 1.4;
    letter-spacing: 0;
}

// label
@mixin labelLarge {
    font-family: $avantGarde;
    font-size: 1.4rem;
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: uppercase;
}

@mixin labelMedium {
    font-family: $avantGarde;
    font-size: 1.2rem;
    line-height: 1.8;
    letter-spacing: 0.02em;
}

@mixin labelSmall {
    font-family: $avantGarde;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: uppercase;
}

// caption
@mixin captionLarge {
    font-family: $nanum;
    font-size: 1.4rem;
    line-height: 1.2;
    letter-spacing: 0;
}

@mixin captionSmall {
    font-family: $nanum;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0;
}
