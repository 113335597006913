@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/layout';

.root {
    @include grid;

    margin-bottom: var(--component-margin-bottom);
}

.textContainer {
    order: 2;
    grid-column: 1 / -1;
}

.figure {
    grid-column: 1 / -1;
    order: 1;
    transition: transform 150ms linear;
    min-height: 37.7rem;
    margin-bottom: 5rem;

    &.sticky {
        margin-top: -5rem;
    }

    &.ribbons,
    &.bike {
        mix-blend-mode: multiply;
    }
}

.eyebrow {
    display: inline-block;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: $pink;
}

.headline {
    margin-bottom: 0;
    max-width: 38rem;
    text-wrap: balance;

    > b {
        color: $pink;
    }

    &:last-child {
        margin: 0;
    }
}

.bodyCopy {
    color: $grayDarker;
    margin: 2rem 0 1.8rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.imageContainer {
    video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .isInView & {
        animation: bounceY 6s infinite ease;
    }
}

.shadowContainer {
    width: 100%;
    height: 2rem;

    .isInView & {
        animation: bounceShadow 6s infinite ease;
        opacity: 0.5;
        transform: scale(0.7);
    }
}

.shadow {
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.button {
    margin-top: var(--cta-margin-top);
}

@keyframes bounceShadow {
    50% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounceY {
    50% {
        transform: translateY(3rem);
    }
}

@include small {
    .textContainer {
        order: 1;
        grid-column: 1 / 5;
    }

    .bodyCopy {
        margin: 0;
    }

    .figure {
        order: 2;
        grid-column: 5 / 9;
        min-height: 50rem;
        margin-bottom: 0;
    }

    .headline {
        margin-bottom: 3rem;
    }
}

@include medium {
    .shadowContainer {
        height: 4rem;
    }

    .textContainer {
        order: 1;
        grid-column: 1 / 7;

        .invertLayout & {
            order: 2;
            grid-column: 8 / -1;
        }
    }

    .figure {
        order: 2;
        grid-column: 8 / -1;
        min-height: 52rem;

        .invertLayout & {
            order: 1;
            grid-column: 1 / 7;
        }

        &.sticky {
            margin-top: -15rem;
        }
    }

    .figureInner {
        transform: translateX(-10%);
    }

    .eyebrow {
        margin-bottom: 3rem;
    }

    .headline {
        max-width: 61rem;
        margin-bottom: 7rem;
    }

    .bodyCopy {
        max-width: 40rem;
    }
}

@include large {
    .figure {
        min-height: 66rem;

        &.sticky {
            margin-top: -15rem;
        }
    }

    .figureInner {
        transform: translateX(-10%);
    }

    .eyebrow {
        margin-bottom: 3rem;
    }
}
