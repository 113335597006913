@import 'styles/colors';
@import 'styles/breakpoints';

.root {
    background-color: $beigeDark;
    overflow: hidden;
}

.seeMoreButton {
    margin: 0 auto;
    margin-top: var(--cta-margin-top);
}
