@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/layout';

.root {
    --buttonHeight: 4rem;

    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner {
    @include grid;

    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}

.filters {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.clear {
    width: 100%;
    padding: 1rem 0 2rem;
    grid-column: 1 / -1;
}

.clearButton {
    background-color: rgba($gray, 0.5);
    height: var(--buttonHeight);
    width: 100%;
    color: $pink;
    transition: background-color $fast $easeOutQuad;

    @media (hover: hover) {
        &:hover {
            background-color: rgba($gray, 0.8);
        }
    }

    p {
        text-transform: capitalize;
        text-decoration: underline;
    }
}

.activeFilters {
    width: 100%;
    grid-column: 1 / -1;
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.activeFilterPill {
    display: inline-block;
    padding: 0.8rem 1.4rem;
    cursor: pointer;
    background-color: rgba($pink, 0.1);
    border-radius: 3rem;
    margin: 0 1rem 1rem 0;

    p {
        font-size: 1.2rem;
        display: flex;
    }

    transition: background-color $fast $easeOutQuad;

    @media (hover: hover) {
        &:hover {
            background-color: rgba($pink, 0.2);
        }
    }
}

.activeFilterPillIcon {
    position: relative;
    display: block;
    width: 0.8rem;
    margin-left: 1.3rem;
    color: $pink;
}

.selectRoot {
    position: relative;
    width: 100%;
    height: var(--buttonHeight);
    color: $pink;
    border: 1px solid $gray;
    border-bottom: 0;
    margin-bottom: 1rem;

    &:last-child {
        border: 1px solid $gray;
    }

    .hasActiveFilters & {
        margin-bottom: 0;
    }
}

.select {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
}

.selectDisplay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}

.selectDisplayIcon {
    position: relative;
    width: 1rem;
}

.checkboxesRoot {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.checkboxesHeadline {
    padding: 2.4rem 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
}

.toggleButton {
    display: flex;
    cursor: pointer;
}

.checkboxesHeadlineIcon {
    position: relative;
    width: 1.2rem;
    color: $pink;
    display: block;
    margin: 0.2rem 0 0 1rem;
    transform: rotate(180deg);

    .collapsed & {
        transform: rotate(0deg);
        margin: -0.2rem 0 0 1rem;
    }
}

.resultCount {
    text-align: right;
    justify-self: flex-end;
}

.checkboxesInner {
    width: 100%;
    display: flex;
    border-top: 0.1rem solid $gray;
}

.checkboxesCollapse {
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 0;
    transition: height $fast $easeInOutCubic;
}

.checkboxesGroup {
    position: relative;
    width: 100%;
    margin-right: 1rem;
    border-bottom: 0.1rem solid $gray;
    padding-top: 1rem;

    &:last-child {
        margin-right: 0;
    }
}

.checkboxesGroupInner {
    overflow-y: auto;
    height: calc(100% - 4.4rem);
}

.checkboxInput {
    display: none;
}

.checkboxesGroupHeader {
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.1rem solid $pink;
}

.checkboxLabel {
    cursor: pointer;

    p {
        text-transform: capitalize;
        line-height: 1.8;
    }

    @media (hover: hover) {
        &:hover {
            color: $gold;

            .active & {
                color: $black;
            }
        }
    }

    &:active {
        color: $black;
    }
}

.checkbox {
    display: flex;
    color: $grayDark;
    user-select: none;

    &.active {
        color: $black;
    }

    &:last-child {
        margin-bottom: 1rem;
    }
}

@include small {
    .inner {
        padding-left: var(--grid-margin);
        padding-right: var(--grid-margin);
    }

    .selectRoot {
        border: 1px solid $gray;
        border-right: 0;

        &:last-child {
            border: 1px solid $gray;
        }
    }

    .filters {
        flex-direction: row;
    }

    .activeFilterPill {
        padding: 1.2rem 1.7rem;
    }
}

@include medium {
    .root {
        --buttonHeight: 5rem;
    }

    .activeFilters {
        margin-top: 2.4rem;
        margin-bottom: 0.4rem;
    }

    .checkboxesGroup {
        padding-top: 2.4rem;
        margin-right: 2.4rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .checkboxesGroupHeader {
        padding-bottom: 1.4rem;
        margin-bottom: 1.4rem;
    }

    .checkboxesGroupInner {
        height: calc(100% - 6rem);
    }

    .checkboxLabel {
        p {
            line-height: 2;
            font-size: 1.8rem;
        }
    }
}
