@import 'styles/animations';
@import 'styles/colors';
@import 'styles/typography';
@import 'styles/breakpoints';

@mixin buttonHover {
    color: $white;

    &::before {
        transform: translateX(0);
    }

    .buttonIcon {
        color: $white;
        transform: translateX(0.5rem);
    }
}

.root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    padding: 0.4rem 1.6rem;
    border: 0.2rem solid $gold;
    background: transparent none;
    text-decoration: none;
    transition: all $fast $easeInOutQuad;
    overflow: hidden;
    color: inherit;

    &::before {
        transition: transform $fast $easeInOutQuad;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: $gold;
        width: 100%;
        height: 100%;
        transform: translateX(-101%);
    }

    &:focus {
        @include buttonHover;
    }

    @media (hover: hover) {
        &:hover {
            @include buttonHover;
        }
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.4;
        border-color: inherit;
        color: inherit;
    }
}

.buttonText {
    position: relative;
    padding-right: 1.546rem;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
}

.buttonIcon {
    width: 2.2rem;
    height: 3.2rem;
    color: $gold;
    transition: all 0.2s $easeInOutQuad;
}

@include small {
    .root {
        padding: 1.4rem 3rem;
    }

    .buttonIcon {
        width: 3.2rem;
    }
}
