$black: #010101 !default;
$white: #fff !default;
$gray: #d1d0cc !default;
$grayDark: #9b9a97 !default;
$grayDarker: #767573 !default;
$beige: #faf9f4 !default;
$beigeDark: #f2f1ed !default;
$pink: #fa1682 !default;
$pinkBright: #fa1ea3 !default;
$blue: #74ccdf !default;
$gold: #d7ae5c !default;
$purple: #a47eb9 !default;
$green: #74e1c1 !default;
