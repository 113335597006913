@import 'styles/colors';

.root {
    overflow: hidden;
    padding-bottom: 0 !important; // padding is added back with ctaContainer
    background-color: $beige;
}

.ctaContainer {
    position: relative;
    background-color: $white;
    padding-top: var(--cta-margin-top);
    padding-bottom: var(--block-padding-top);
    z-index: 2; //hides popcorn kernels
}

.calltoaction {
    margin: 0 auto;
}

.sectionContainer {
    position: relative;
    z-index: 10;
}
