@import 'styles/animations';

.root {
}

.fadeIn {
    transition: opacity $slow $easeOut;
    opacity: 0.001;
    will-change: opacity;
}

.loaded {
    opacity: 1;
    will-change: auto;
}

.debug {
    outline: 2px solid red;
    filter: blur(2px) saturate(0.5);
}
