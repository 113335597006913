@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner {
    position: relative;
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: center;

    .player {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        > div {
            padding-top: 56.3% !important;
            background-color: $black;
        }
    }

    .posterContainer {
        width: 100%;
        opacity: 1;
        padding-top: 56.3%; // cover bleeding edge
        position: relative;
        transition:
            opacity $baseDuration,
            visibility $baseDuration;
        visibility: visible;
        z-index: 1;

        .isPlaying & {
            transition:
                opacity $baseDuration,
                visibility $baseDuration;
            visibility: hidden;
            opacity: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0;
            transition: opacity $fast $easeOutQuad;
        }

        .isHovering & {
            &::after {
                opacity: 0.5;
            }
        }
    }

    video,
    img {
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        object-fit: fill;
        pointer-events: none;
    }
}

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1;
    transition:
        opacity $baseDuration,
        visibility $baseDuration;

    .isPlaying & {
        opacity: 0;
    }
}
